<template>
  <b-form-group :label="$t('add_product/main_image')" label-for="Main Image">
    <validation-provider #default="{ errors }" name="Main Image">
      <div class="main-image">
        <div class="main-img">
          <div
            class="image-controlles"
            v-if="!!globalPermission.products.update"
          >
            <b-button variant="primary" size="sm">
              <b-form-file
                :disabled="!!!globalPermission.products.update"
                name="mainImageInput"
                ref="mainImage"
                accept="image/*"
                class="main-image-input"
                v-model="file"
                :state="errors.length > 0 ? false : null"
                @change="handleProductMainImage"
              />
              {{ $t("replace_image") }}
            </b-button>
          </div>
          <img
            class="main-img"
            :src="productMainImage || (image && image.path) || addImageIcon"
            style="height: 100%; object-fit: cover"
            alt="product main image"
          />
        </div>
      </div>
      <RecommendationUploadImage :dimensions="{ width: 1024, height: 1024 }" />
      <small class="text-danger">
        {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
      </small>
      <overlay-loading :isLoading="imageLoading" />
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BButton, BFormFile, BFormGroup, BFormInput } from "bootstrap-vue";
import OverlayLoading from "@/components/shared/OverlayComponent/index.vue";
import { ValidationProvider } from "vee-validate";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "ProductMainImageComponent",
  props: {
    image: {
      required: false,
    },
  },
  components: {
    BFormInput,
    BButton,
    ValidationProvider,
    OverlayLoading,
    BFormGroup,
    BFormFile,
    RecommendationUploadImage,
  },

  data() {
    return {
      file: null,
      productMainImage: null,
      addImageIcon: require("@/assets/images/icons/add-image-icon.svg"),
      imageLoading: false,
    };
  },
  methods: {
    async handleProductMainImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.productMainImage = URL.createObjectURL(file);
        this.$emit("set-main-image", { file });
      }
    },
  },
};
</script>
<style lang="scss">
.main-image {
  width: 187px;
  height: 178px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  border: 2px dashed #e3e3e3;
  background: #fff;
  padding: 1px;
  position: relative;
  .main-image-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    .custom-file-label {
      cursor: pointer;
    }
  }
  .main-img {
    width: 180px;
    height: 170px;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    img {
      min-width: 180px;
      object-fit: cover;
      border-radius: 5px;
    }
    .image-controlles {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.5s;
      position: absolute !important ;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      z-index: 2;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.671);
      button {
        position: relative;
      }
    }
    .image-controlles:hover {
      opacity: 1 !important;
    }
  }
}
</style>
