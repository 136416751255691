<template>
  <b-card class="catalog-card-container">
    <div class="content-box">
      <div v-if="catalogMainImage && catalogMainImage.length > 0">
        <b-link
          target="_blank"
          :to="`/catalog/show-catalog/${selectedCatalogProp.slug}`"
        >
          <img
            class="catalog-main-image"
            :src="catalogMainImage[0].path"
            :alt="selectedCatalogProp.name"
          />
        </b-link>
      </div>
      <div>
        <div style="display: flex; flex-wrap: wrap">
          <P
            v-for="(item, index) in catalogTree"
            :key="index"
            style="font-size: 14px; color: #1f1f1f"
            >{{ `${item}${index == catalogTree.length - 1 ? "" : " /"}` }}</P
          >
        </div>
        <P style="font-size: 24px; color: #3f395a">
          <b-link
            target="_blank"
            :to="`/catalog/show-catalog/${selectedCatalogProp.slug}`"
          >
            {{ selectedCatalogProp.name }}
          </b-link>
        </P>
        <P style="font-size: 18px; color: #787878">{{
          selectedCatalogProp.made_in
        }}</P>
        <P style="font-size: 18px; color: #003e5b">{{
          selectedCatalogProp.brand.name
        }}</P>
      </div>
      <div class="images-box">
        <div v-for="image in catalogRegularImage" :key="image.id">
          <img
            class="catalog-images"
            v-if="image.path"
            :src="image.path"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="discription-box">
      <h6 style="font-size: 18px; color: #1f1f1f">
        {{ $t("add_product/catalog/description") }}
      </h6>
      <p style="font-size: 16px; color: #6d6d6d">
        {{ selectedCatalogProp.description }}
      </p>
    </div>
  </b-card>
</template>

<script>
import { BFormInput, BCol, BRow, BCard, BLink } from "bootstrap-vue";
export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BLink,
  },
  props: ["selectedCatalogProp"],
  computed: {
    catalogMainImage() {
      return this.selectedCatalogProp?.images?.filter((item) => item.is_main);
    },
    catalogRegularImage() {
      return this.selectedCatalogProp?.images?.filter((item) => !item.is_main);
    },
    catalogTree() {
      return this.handleCatalogTree(this.selectedCatalogProp);
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    handleCatalogTree(catalogData) {
      const tree = [];
      if (catalogData) {
        for (let index in catalogData.category_parents) {
          tree.splice(index - 1, 0, catalogData.category_parents[index].name);
        }
        tree.push(catalogData.name);
      }
      return tree;
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
